"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultConfig = void 0;
exports.configurePage = configurePage;
exports.defaultConfig = {
    bottomBanner: undefined,
    defaultButtons: [],
    defaultNotes: [],
    hasReadReceipt: false,
    hasSubmitReceipt: false,
    instructions: undefined,
    isLoginPromptDisabled: false,
    isMinimalLinking: false,
    isUploadsDisabled: false,
    isCaptureDisabled: false,
    isWhitelabeled: false,
    isReportIssueButtonDisabled: false,
    isPoweredByFooterDisabled: false,
    isTranscriptionEnabled: false,
    legalLinkReplacements: undefined,
    mediaUploadLimit: 1,
    permittedUploadTypes: ['video/*'],
    postSubmissionInstructions: undefined,
    processingInstructions: undefined,
    topBanner: undefined,
    videoTimeLimitInMinutes: 5,
};
function configurePage(page, manualConfig = {}) {
    const pageConfig = page?.config || {};
    const campaignConfig = page?.campaign?.config || {};
    return Object.assign({}, exports.defaultConfig, campaignConfig, pageConfig, manualConfig);
}
