import React from 'react';
import { Link } from 'react-router-dom';
import { configurePage } from '@repo/page-configurator';

import { useAuth } from '../hooks/useAuth';
import ReportIssue from './ReportIssue';
import SpokenoteLogoFooter from '../images/logo-footer.svg';

function Footer({ page }) {
  const { currentUser } = useAuth();
  const config = React.useMemo(() => configurePage(page), [page]);

  return (
    <div className="footer-whitelabeled">
      {config.isWhitelabeled && !config.isPoweredByFooterDisabled && (
        <a href="https://spokenote.com">
          <div className="powered-by-spokenote">
            <span>Powered by</span>
            <SpokenoteLogoFooter />
          </div>
        </a>
      )}

      <div className="footer-navigation">
        <ul>
          {page && !config.isReportIssueButtonDisabled && (
            <li>
              <ReportIssue currentUser={currentUser} />
            </li>
          )}
          {page && config.isWhitelabeled && !config.isMinimalLinking && (
            <li>
              {currentUser ? (
                <Link to="/">My Account</Link>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </li>
          )}
          <li>
            <TermsOfServiceLink page={page} config={config} />
          </li>
        </ul>
      </div>
    </div>
  );
}

function TermsOfServiceLink({ page, config }) {
  const label = 'Legal Terms';
  const { legalLinkReplacements } = config;
  if (page && legalLinkReplacements && legalLinkReplacements.termsOfService === false) {
    return null;
  }

  if (page && legalLinkReplacements && typeof legalLinkReplacements.termsOfService === 'string') {
    return (
      <FooterLink
        href={legalLinkReplacements.termsOfService}
        label={label}
      />
    );
  }

  return (
    <FooterLink
      href="/legal"
      label={label}
    />
  );
}

function FooterLink({ href, label }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );
}

export default Footer;
