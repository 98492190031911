export function isPhotoUpload(config) {
  return config.permittedUploadTypes?.includes('image/*');
}

export function isVideoUpload(config) {
  return config.permittedUploadTypes?.includes('video/*');
}

export function isPhotoAndVideoUpload(config) {
  return isPhotoUpload(config) && isVideoUpload(config);
}

export function getMediaOptionsString(config) {
  const options = [];
  if (isPhotoUpload(config)) {
    options.push('image');
  }
  if (isVideoUpload(config)) {
    options.push('video');
  }
  return options.join(', or ');
}
