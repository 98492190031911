import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthLayout } from './hooks/useAuth';

import './styles/App.scss'; // Global Styles

import AccessContent from './pages/AccessContent';

const AccountSettings = lazy(() => import('./pages/AccountSettings'));
const Help = lazy(() => import('./pages/Help'));
const Signup = lazy(() => import('./pages/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Legal = lazy(() => import('./pages/legal/Legal'));
const LegalTermsOfService = lazy(() => import('./pages/legal/TermsOfService'));
const LegalPrivacyPolicy = lazy(() => import('./pages/legal/PrivacyPolicy'));
const LegalGDPRPolicy = lazy(() => import('./pages/legal/GDPRPolicy'));
const LegalDoNotSell = lazy(() => import('./pages/legal/DoNotSell'));
const PurchaseSuccessful = lazy(() => import('./pages/redirects/PurchaseSuccessful'));
const PurchaseCanceled = lazy(() => import('./pages/redirects/PurchaseCanceled'));
const ReportIssue = lazy(() => import('./pages/ReportIssue'));
const MyNotes = lazy(() => import('./pages/MyNotes'));
const Login = lazy(() => import('./pages/Login'));
const Error404 = lazy(() => import('./pages/Error404'));

function OldAppRedirect() {
  const { videoId } = useParams();
  return <Navigate to={`/access/${videoId}`} />;
}

// These are old routes that are no longer used, but we want to redirect to the new app
const oldRoutes = [
  {
    path: '/view-video/:videoId',
    element: <OldAppRedirect />,
  },
  {
    path: '/create-video/:videoId',
    element: <OldAppRedirect />,
  },
];

const routes = [
  ...oldRoutes,
  {
    path: '/health-check',
    element: <div>OK</div>,
  },
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/',
        element: <Navigation />,
        children: [
          {
            path: '/access/:pageId',
            element: <AccessContent />,
          },
          {
            path: '/a/01/:pageId',
            element: <AccessContent />,
          },
          {
            path: '/a/:pageId',
            element: <AccessContent />,
          },
          {
            path: '/page/:pageId',
            element: <AccessContent />,
          },
          {
            path: '/report-issue',
            element: <ReportIssue />,
          },
          {
            path: '/signup',
            element: <Signup />,
          },
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: '/password',
            element: <ForgotPassword isPhone />,
          },
          {
            path: '/legal',
            element: <Legal />,
          },
          {
            path: '/legal/terms-of-service',
            element: <LegalTermsOfService />,
          },
          {
            path: '/legal/privacy-policy',
            element: <LegalPrivacyPolicy />,
          },
          {
            path: '/legal/gdpr-policy',
            element: <LegalGDPRPolicy />,
          },
          {
            path: '/legal/do-not-sell',
            element: <LegalDoNotSell />,
          },
          {
            path: '/account-settings',
            element: <ProtectedRoute><AccountSettings /></ProtectedRoute>,
          },
          {
            path: '/purchase-successful',
            element: <PurchaseSuccessful />,
          },
          {
            path: '/purchase-canceled',
            element: <PurchaseCanceled />,
          },
          {
            path: '/help',
            element: <Help />,
          },
          {
            path: '/',
            element: <ProtectedRoute><MyNotes /></ProtectedRoute>,
          },
          {
            path: '*',
            element: <Error404 />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes, {
  future: {
    v7_normalizeFormMethod: true,
  },
});

function App() {
  return (
    <Suspense>
      <RouterProvider router={router} />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
        }}
      />
    </Suspense>
  );
}

export default App;
