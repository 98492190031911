import React from 'react';
import { PageStatus } from '@repo/constants';

import SpokenoteStickerAnimated from '../../images/spokenote-sticker-animated.svg';

function NoteErrorState({ page }) {
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!page?.currentVersion) {
      setError('There was an error while uploading your content.\nUse the "Report Issue" tool below to send us a message.');
    } else if (page.pageStatus === PageStatus.ERRORED) {
      setError('There was an error while processing your content.\nUse the "Report Issue" tool below to send us a message.');
    }
  }, [page]);

  return (
    <div className="error">
      <div className="spokenote-sticker-visual">
        <SpokenoteStickerAnimated />
      </div>
      <div className="spokenote-sticker-visual-title">
        <h4>Error State</h4>
        <p>{error}</p>
      </div>
    </div>
  );
}

export default NoteErrorState;
