import React from 'react';
import { configurePage } from '@repo/page-configurator';
import Markdown from 'react-markdown';

import Note from '../EditorViews/Note';
import useSessionStorage from '../../hooks/useSessionStorage';

function NoteViewState({ page, setPage }) {
  const config = React.useMemo(() => configurePage(page), [page]);
  const [submissionFlag] = useSessionStorage(`submitted-${page.id}`, false);

  let image;
  if (config.topBanner && !config.topBanner.isAttachedToNote) {
    image = (
      <img
        src={config.topBanner.imageUrl}
        alt={config.topBanner.imageAlt || 'Top Banner'}
        style={config.topBanner.maxWidth ? { maxWidth: `${config.topBanner.maxWidth}px` } : {}}
      />
    );
  }

  return (
    <div className="note-content">
      {config.topBanner && !config.topBanner.isAttachedToNote && (
        <div className="sponsor-banner">
          {config.topBanner.linkUrl ? (
            <a href={config.topBanner.linkUrl} target="_blank" rel="noreferrer">
              {image}
            </a>
          ) : (image)}
        </div>
      )}

      <Note page={page} note={page.currentVersion?.note} />

      {submissionFlag && config.postSubmissionInstructions && (
        <div className="post-submission-instructions spokenote-sticker-visual-title">
          <h4 className="color-primary">
            {config.postSubmissionInstructions.title}
          </h4>
          <div className="instructions-description" style={{ textAlign: config.postSubmissionInstructions.descriptionTextAlignment || 'center' }}>
            <Markdown>
              {config.postSubmissionInstructions.description}
            </Markdown>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoteViewState;
